import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import React, { Suspense, lazy } from "react";
const InstagramEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.TwitterEmbed,
  }))
);

const Semana26: React.FC = () => {
  const Notice1 = `
  Por segundo año consecutivo, los contratos con las empresas de aseo de Cali (Ciudad Limpia, Veolia, Promovalle y Promocali) serán renovados a través de Emsirva E.S.P. en Liquidación. Así lo anunció el concejal Rodrigo Salazar, quien advirtió que la ciudad aún no cuenta con un nuevo modelo de aseo, pese a que en la última prórroga de los contratos, el alcalde Alejandro Eder se comprometió a consolidar un nuevo sistema para la capital del Valle.
“Ya pasó un año y los caleños seguimos a la espera de que el Alcalde nos presente el modelo de aseo que no se limite a la recolección de basura, sino que incentive la separación en la fuente de los residuos sólidos y su aprovechamiento”, subrayó el cabildante.
Actualmente, se están adelantando mesas técnicas con representantes de Emsirva, la Superintendencia de Servicios Públicos y la Unidad Administrativa Especial de Servicios Públicos para definir el plazo y las condiciones de la prórroga de los contratos, debido a que Emsirva no tiene la capacidad para asumir la operación del servicio de aseo en la ciudad.
“Desde nuestra curul, también hemos venido insistiendo en la recuperación de Emsirva como patrimonio de los caleños, empresa pública intervenida por la Superintendencia de Servicios Públicos desde 2015, que reporta importantes utilidades para la ciudad y que, además, logró solventar el pasivo pensional para responder a 1.230 jubilados, que representan el 61% de sus pasivos”, indicó Rodrigo Salazar.
En este sentido, también se está a la espera de que, en los próximos días, la Administración Distrital exponga su plan de acción respecto a la liquidación de Emsirva y la posible transferencia de la empresa a la ciudad.
“Cali tiene todo el derecho de tener la hegemonía y control en el servicio de aseo, y no que otras entidades decidan por nosotros”, precisó.
Críticas a las empresas de aseo continúan
Los contratos a los operadores de aseo serán renovados, pese a que han sido blanco de críticas constantes por parte de los ciudadanos, quienes vienen denunciando retrasos en los horarios y frecuencias de recolección de la basura, ineficiencia en la atención de los residuos especiales (como colchones, muebles y escombros), coberturas limitadas en el servicio e incumplimiento los cronogramas de poda de árboles y corte de césped.
“Todo ello, sumado a la falta de cultura ciudadana, ocasiona la acumulación de desechos en las vías, andenes, separadores viales, canales de aguas lluvias, parques y espacios públicos de la ciudad, que permanentemente se ven impactados por basura, lo que genera afectaciones ambientales, sanitarias y paisajísticas para las personas”, sostuvo Salazar.
Y es que en la capital del Valle se tienen identificados cerca de 120 puntos de arrojo clandestino y más de 10 escombreras ilegales, en las que van a parar cerca del 60% de los Residuos de Construcción y Demolición que se generan en la ciudad, tal como lo denunció el cabildante.
“De allí la importancia de que la Administración Distrital apueste por estrategias como la construcción de un punto limpio para la recepción, tratamiento y aprovechamiento de estos materiales, como una alternativa sostenible que permita cumplir con el cierre de la escombrera de la 50, que a lo largo de los años se ha visto afectada por la acumulación de RCD, lo que ha generado un perjuicio ambiental y paisajístico para la comunidad del sector”, opinó.
Mejorar la tasa de aprovechamiento de residuos, clave para avanzar hacia una Cali circular
Cali, al igual que muchas otras grandes ciudades de Colombia y del mundo, enfrenta un enorme desafío en el manejo de sus residuos sólidos. Diariamente, en la ciudad se generan aproximadamente 1.900 toneladas de basura, lo que equivale a cerca de 57.000 toneladas al mes y 684.000 toneladas al año.
De acuerdo con el concejal Rodrigo Salazar, esta situación preocupa si se tiene en cuenta que la tasa de aprovechamiento de residuos en la ciudad tan solo alcanza el 7%, cifra que está por debajo del promedio mundial (14%) y de algunos países desarrollados, donde la estadística supera el 80%.
Ante este panorama, el cabildante plantea que “todavía tenemos mucho que mejorar en materia de gestión de residuos, empezando por los cambios estructurales que requiere el modelo de aseo, en el que se les remunera a los operadores de aseo por tonelada de residuos recogida, transportada y dispuesta finalmente en el relleno sanitario”, lo cual va en contravía a la economía circular.
“En Cali sale más barato enterrar la basura que aprovecharla. Por ello, es clave entrar en la lógica del aprovechamiento, con la que se reduzca la cantidad de residuos que se generan y llegan hasta el relleno sanitario”, señaló.
Pero, además, Salazar propone la actualización del Plan de Gestión Integral de Residuos Sólidos para que esté alineado con las necesidades y los desafíos, como el crecimiento urbano, la economía circular y las nuevas políticas ambientales, tales como el programa ‘Basura Cero’, con el que se busca incentivar un modelo de aseo que promueva la reducción de la generación de residuos, así como la separación y aprovechamiento.
¡El cambio es con vos!

  `;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <Suspense
          fallback={<div className="loading-placeholder">Loading...</div>}
        >
          <InstagramEmbed
            url="https://x.com/rodrisalazarco/status/1884705391591710893?prefetchTimestamp=1739590517643&mx=2"
            width={328}
          />
        </Suspense>
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
        Contratos con las empresas de aseo serán renovados y la Alcaldía aún no presenta un nuevo modelo de aseo para Cali{" "}
        </h1>
        <h5 className={style.Fecha}>Enero 29 del 2025</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde; Proyectó: Janner Rodríguez Losada{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana26;
