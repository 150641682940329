import style from "../blog.module.css";
import ExpandableText from "../TextoExpandible";
import React, { Suspense, lazy } from "react";
const InstagramEmbed = lazy(() =>
  import("react-social-media-embed").then((module) => ({
    default: module.InstagramEmbed,
  }))
);

const Semana27: React.FC = () => {
  const Notice1 = `Cali cerró el 2024 con 113 mil desempleados, con una tasa de desempleo del 11%, así lo reveló el más reciente informe publicado por el Departamento Nacional de Estadística (DANE).

La tasa de desempleo de Cali es superior al promedio nacional (10,2%) y a la de otras ciudades importantes como Bogotá (9,7%) y Medellín (8,6%) y Barranquilla (10,1%).
Ante esta situación, el concejal del Partido Verde, Rodrigo Salazar, manifestó su preocupación e invitó a la Administración del alcalde Alejandro Eder a aumentar la inversión social e implementar políticas públicas que contribuyan a la generación de empleo. 

“Durante su campaña, el alcalde Eder prometió generar 100.000 empleos para los caleños. Sin embargo, la realidad es otra. En el 2024, Cali fue la ciudad, entre las cinco principales del país, donde se presenta mayor tasa de desempleo”, indicó Salazar.

El último trimestre del año, y con el impulso de grandes eventos como la COP16, que se realizó en Cali entre octubre y noviembre, la tasa de desempleo presentó una reducción estacional y llegó al 9,3%. Sin embargo, esta cifra ubicó a la capital del Valle como la ciudad con mayor desempleo del país durante ese periodo.

El desempleo en Cali subió en 8 de los 13 sectores productivos durante el último trimestre de 2024, siendo el cultural y el artístico los más afectados por el incremento, que tuvo una variación negativa del -12%, que representó 15.000 personas. Otros sectores golpeados fueron Educación y Salud con el -8,2% (12.800 personas), el de servicios públicos con -11,6% (2.100 personas) y el Agropecuario -7,9%.

El sector Comercio presentó una importante recuperación por la temporada navideña y contuvo, temporalmente, el aumento general de la tasa de desempleo.

De acuerdo con el concejal Rodrigo Salazar, la situación más preocupante es en relación con los jóvenes. “Nuevamente, subió el desempleo juvenil a 16,9%, la cifra más alta de los últimos 3 años. Esto muestra el fracaso en las políticas de inclusión productiva de la Secretaría de Desarrollo Económico”, dijo.

“Es necesario que la Alcaldía implemente una estrategia con los empresarios para que el sector privado se sume a la urgencia de generar mejores empleos formales. Nuestros jóvenes, al carecer de oportunidades laborales fijas, son los más vulnerables a los cirulos delictivos”, expresó el cabildante.

La mitad de los caleños están bajo la informalidad

De acuerdo con el DANE, la informalidad laboral en Cali durante el 2024 también subió al 46,6%, representado en 516 mil afectado. En otras palabras, casi la mitad de los caleños que trabajan (1.087.000), lo hacen de manera informal y sin garantías laborales. Entre las 13 principales ciudades del país, esta cifra solo superada por Barranquilla. 

A este panorama, también se le suma el alza del (10,35%) en el pasaje del sistema de transporte masivo MIO, que pasó de $2.900 a $3.200, incremento que estuvo por encima de la inflación (10,34%) 

De igual forma, preocupa el aumento del impuesto predial a 8 comunas de Cali y, lo que es aún más grave, la propuesta de la administración Eder de reducir el subsidio de los servicios públicos en los estratos 1 y 2.

Entre enero y diciembre del 2024, se emplearon cerca de 1,09 millones de personas, 21.371 menos frente al 2023. Esto representó una disminución en la tasa de ocupación en la capital del Valle, que pasó del 59,3% al 57,5%.

`;

  return (
    <section className={style.Noticia}>
      <div className={style.Instagram}>
        <Suspense
          fallback={<div className="loading-placeholder">Loading...</div>}
        >
          <InstagramEmbed
            url="https://www.instagram.com/reel/DGA_49DAV47/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
            width={328}
          />
        </Suspense>
      </div>
      <div className={style.Separador}></div>
      <div className={style.NoticeBox}>
        <h1 className={style.Tittle}>
          Panorama laboral en Cali no presenta mejoría. Se necesita más
          inversión social{" "}
        </h1>
        <h5 className={style.Fecha}>Febrero 15 del 2025</h5>
        <ExpandableText text={Notice1} limit={800} />
        <p className={style.reportero}>
          Oficina de Comunicaciones Concejal Rodrigo Salazar, Partido Alianza
          Verde; Proyectó: Janner Rodríguez Losada{" "}
        </p>
      </div>
    </section>
  );
};

export default Semana27;
